import React, { useEffect, useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AdminServices from '../../services/AdminServices'
import person from '../../assets/images/person.jpeg'
import LoadingIndicator from '../LoadingIndicator'

function NavBar() {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const admin = await AdminServices.getAdminDetails(navigate);
                // console.log('Admin Details:', admin);
                setUser(admin?.data);
            } catch (error) {
                console.error("Error fetching user details:", error);
                toast.error("Unexpected Error Occurred.");
            } finally {
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, [navigate]);

    return (
        <Navbar style={{ paddingBlock: 13, borderBottomWidth: 1, borderBottomColor: "#d1cbcb", borderBottomStyle: "solid" }} bg="light" expand="lg">
            <Nav className="ms-auto">
                {
                    loading ? <div className='me-4'>
                        <LoadingIndicator />
                    </div> : <Nav.Link href="/admin/profile" className='d-flex align-items-center admin-btn'>
                        <span className='fw-fw-semibold mb-0 text-black' style={{ fontSize: 18 }}>
                            {user?.displayName || "Admin"}
                        </span>
                        <img
                            src={user?.imagePath || person}
                            alt="Admin"
                            className='rounded-circle ms-2'
                            style={{ width: '35px', height: '35px', objectFit: "cover" }}
                        />
                    </Nav.Link>
                }
            </Nav>
        </Navbar>
    );
}

export default NavBar;
