import React from 'react';
import { Navigate } from 'react-router-dom';
import AdminServices from '../services/AdminServices';

const AdminPrivateRoute = ({ element: Component, ...rest }) => {
    try {
        const admin = AdminServices.getAdminToken();
        // console.log(admin);
        return admin ? <Component {...rest} /> : <Navigate to="/admin/login" />;
    } catch (error) {
        console.error("Error fetching Admin token:", error);
    }
};

export default AdminPrivateRoute;
