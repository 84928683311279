import React, { useState } from "react";
import styled from "styled-components";
import About from "./About";
import UploadStory from "../UploadStory";
import { BASE_URL } from "../../services/BASE_URL";
import { useNavigate } from "react-router-dom";

const ProfileDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-block: 12px;
  width: 100%;
`;

const ProfileInfo = styled.div`
  padding-top: 60px;
  width: 100%;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 175px;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
`;

const ProfileImage = styled.img`
  position: absolute;
  bottom: -70%;
  left: 13%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  border: 1px solid #919191;
  border-radius: 50%;
  padding: 4px;
  object-fit: cover;
  @media (max-width: 600px) {
    left: 50%;
  }

`;

const ProfileNameButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;
  justify-content: space-between;
  flex-wrap: wrap; 
`;

const ProfileName = styled.h4`
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 20px;
  color: black;
`;

const Button = styled.button`
  background-color: #458ff6;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 20px;
  margin-left: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;


  span {
    display: inline-block;
  }

  @media (max-width: 600px) {
    span {
      display: none;
    };
    padding: 10px;
  }
`;

const ProfileCategory = styled.span`
  color: #919191;
  font-size: 14px;
`;

function ProfileDetails({ medallion, isUser }) {
  const [isModalOpen, setModalOpen] = useState(false);
  // console.log(`${BASE_URL}${medallion.thumbNailPath}`, isUser);

  const navigate = useNavigate();

  return (
    <ProfileDetailsWrapper>
      <ImageWrapper>
        <ThumbnailImage src={`${BASE_URL}${medallion.thumbNailPath}`} alt="Thumbnail" />
        <ProfileImage src={`${BASE_URL}${medallion.imagePath}`} alt="Profile" />
      </ImageWrapper>
      <ProfileInfo>
        {isUser && <div className="d-flex mt-4 justify-content-end">
          <Button onClick={() => navigate(`/edit-deceased-profile/${medallion.id}`)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
            </svg>
            <span className="ps-1 fw-normal" style={{fontSize: 17}}>Edit Profile</span>
          </Button>
          <Button onClick={() => setModalOpen(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
            </svg>
            <span className="ps-1 fw-normal" style={{fontSize: 17}}>Create Story</span>
          </Button>
          {/* <Button onClick={() => {
            navigate(`/QR/${medallion.qrCodeId}`)
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
            </svg>
            <span className="ms-1 fw-normal" style={{fontSize: 17}}>View QR Code</span>
          </Button> */}
        </div>}
        <ProfileNameButton>
          <div>
            <ProfileName>{medallion.displayName}</ProfileName>
            <ProfileCategory>My Beloved {medallion.relationship}</ProfileCategory>
          </div>
        </ProfileNameButton>
        <About about={medallion.bioInformation} />
      </ProfileInfo>
      <UploadStory
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        medallionId={medallion.id}
      />
    </ProfileDetailsWrapper>
  );
}

export default ProfileDetails;
