import React, { useState } from 'react';
import Sidebar from '../../components/admin/Sidebar';
import NavBar from '../../components/admin/NavBar';
import AdminServices from '../../services/AdminServices';
import { toast } from 'react-toastify';

function CreateQRCodes() {
    const [numOfQRCodes, setNumOfQRCodes] = useState('');

    const handleCreateQRCodes = async () => {
        // console.log(`Creating ${numOfQRCodes} QR codes`);
        if (numOfQRCodes < 1) {
            toast.error("QR CODE Count should be greater than 1");
            return;
        }
        const res = await AdminServices.createQRCodes(numOfQRCodes);
        if (res.statusCode === 200) {
            toast.success("QR Codes created successfully");
            setNumOfQRCodes('');
        } else {
            toast.error("UnExpected Error Occured. Try Again");
        }
    };

    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                <div className='p-3'>
                    <h2>Create QR Codes</h2>
                    <div className='mt-4 w-50 mx-auto d-flex flex-column align-items-center'>
                        <input
                            type='number'
                            className='form-control'
                            placeholder='Enter number of QR codes'
                            value={numOfQRCodes}
                            onChange={(e) => setNumOfQRCodes(e.target.value)}
                        />
                        <button
                            className='btn btn-primary rounded-5 px-3 py-2 my-4'
                            onClick={handleCreateQRCodes}
                        >
                            Create QR Codes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateQRCodes;
