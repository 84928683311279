import React, { useState } from 'react';
import Header from '../components/Header';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

function ForgetPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(''); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Email is required.');
      return;
    }
    
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Invalid email address.');
      return;
    }
    
    // console.log("Email: ", email);
    navigate('/login');
  };

  return (
    <div className='main_container' style={{ minHeight: "100vh" }}>
      <div className='screen_container justify-content-between'>
        <Header />
        <div>
          <p className='text-black text-center my-2'>Reset Password</p>
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1'>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Control 
                  type="email" 
                  placeholder="Email Address" 
                  className='input' 
                  value={email} 
                  onChange={handleEmailChange} 
                />
                {error && <p className='text-danger'>{error}</p>}
              </Form.Group>
              <Button className="w-100 actionBtn mb-1" type="submit">
                Reset Password
              </Button>
            </Form>
          </div>
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1 mt-3'>
            <p className='text-black text-center mb-2'>Back to Login?</p>
            <Button className='w-100 signupbtn mt-2 text-black' id="signupbtn" type="button" onClick={() => navigate('/login')}>
              Sign in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
