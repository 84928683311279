import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import profile from '../assets/images/person.jpeg';
import useAuthServices from '../services/AuthServices';

function OwnerProfileScreen() {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const { getUserDetails, logout } = useAuthServices();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const getUser = await getUserDetails(navigate);
                setUser(getUser.data);
            } catch (error) {
                console.error("Error fetching user details:", error);
                // toast.error("Session Expired. Login Again");
            } finally {
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, [navigate]);


    return (
        <div>

            <div className='main_container h-auto' style={{ minHeight: "70vh" }}>
                <div className='screen_container d-flex justify-content-center align-items-center' style={{ height: loading ? "80vh" : "auto" }}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='w-50'>
                                    <Header />
                                </div>
                                <div className='w-auto d-flex align-items-center'>
                                    <Button className='profile-btn me-2 px-2' onClick={() => navigate('/notifications', { state: { user } })}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#458ff6" class="bi bi-bell-fill" viewBox="0 0 16 16">
                                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                                        </svg>
                                    </Button>
                                    <Button className='profile-btn me-2 px-2' onClick={() => navigate('/requests', { state: { user } })}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#458ff6" class="bi bi-person-fill-add" viewBox="0 0 16 16">
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                            <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                            <ToastContainer toastClassName='toaster' />
                            <div className='d-flex flex-column align-items-center mt-3'>
                                {user && (
                                    <>
                                        <img src={user.imagePath || profile} alt='profile' className='profile-image mb-2' />
                                        <h4 className='text-black fw-2 text-center w-50'>Hi, {user.displayName}</h4>
                                        <p className='text-black text-center w-75'>
                                            Preserve cherished memories and share the stories of your ancestors or loved ones with others. Ensure their legacy lives on through meaningful tributes and remembrances.
                                        </p>
                                    </>
                                )}
                            </div>
                            <div className='mx-auto mb-2 btnCon' style={{ width: "40%" }}>
                                <Button className='profile-btn h-auto py-2 w-100' onClick={() => navigate(`/edit-profile/${user?.id}`)}>
                                    <p className='text-black mb-0 text-center'>Edit Your Profile</p>
                                </Button>
                            </div>
                            <div className='mx-auto mb-2 btnCon' style={{ width: "40%" }}>
                                <Button className='profile-btn h-auto py-2 w-100' onClick={() => navigate('/memorial-profiles')}>
                                    <p className='text-black mb-0 text-center'>View Departed Profiles</p>
                                </Button>
                            </div>
                            <div className='mx-auto mb-2 btnCon' style={{ width: "40%" }}>
                                <Button className='profile-btn h-auto py-2 w-100' onClick={() => {
                                    // window.open('/shop', '_blank')
                                }}>
                                    <p className='text-black mb-0 text-center'>Buy QR Code</p>
                                </Button>
                            </div>
                            <div className='mx-auto mt-5' style={{ width: "30%" }}>
                                <Button className='profile-btn h-auto p-2 w-100' onClick={() => {
                                    logout();
                                    navigate('/login');
                                }}>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                                        </svg>
                                        <p className='ms-2 text-black mb-0 text-center'>Logout</p>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
}

export default OwnerProfileScreen;
