import React from 'react'
import { BASE_URL } from '../services/BASE_URL';
import { useNavigate } from 'react-router-dom';

function DepartedProfiles({ img, name, relationship, id }) {
    // // console.log(img);
    const navigate = useNavigate();
    return (
        <div className='d-flex mb-2 departed-profile-border py-2' style={{cursor: "pointer"}} onClick={()=>{
            navigate(`/d-profile/${id}`);
        }}>
            <img src={`${BASE_URL}${img}`} alt='Loading' className='handleprofiles' />
            <div className='ms-2'>
                <h5 className='text-black mb-0'>{name}</h5>
                <p className='text-secondary small mb-0'>My Beloved {relationship}</p>
            </div>

        </div>
    )
}

export default DepartedProfiles