import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/admin/Sidebar';
import NavBar from '../../components/admin/NavBar';
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_URL } from '../../services/BASE_URL';
import AdminServices from '../../services/AdminServices';

function ViewQRCodes() {
    const [qrCodeData, setQrCodeData] = useState([]);
    const [filteredQRCodes, setFilteredQRCodes] = useState([]);
    const [filterDate, setFilterDate] = useState(null);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        const fetchQrCodes = async () => {
            setLoading(true);
            try {
                const response = await AdminServices.GetAllQrCodes(currentPage);
                // console.log(response);
                if (response) {
                    setQrCodeData(response.data);
                    setFilteredQRCodes(response.data);
                    const pages = Math.floor(response.recordsTotal / 50) + 1;
                    setTotalPages(pages);
                }
            } catch (error) {
                console.error('Error fetching QR codes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchQrCodes();
    }, [currentPage]);

    const handleFilterByDate = (date) => {
        setFilterDate(date);
        if (date) {
            const selectedDate = date.toISOString().split('T')[0];
            const filtered = qrCodeData.filter((qrCode) => qrCode.createdAt.startsWith(selectedDate));
            setFilteredQRCodes(filtered);
        } else {
            setFilteredQRCodes(qrCodeData);
        }
    };

    const downloadQRCode = (url, uuid) => {
        saveAs(`${BASE_URL}${url}`, `${uuid}.png`);
    };

    const downloadAllQRCodes = async () => {
        setLoading(true);
        AdminServices.DownloadAllQrCodes(currentPage, filterDate).then(()=>{
            setLoading(false)
        })
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                <div className='p-4 mb-3' style={{ width: "100%",height: 'calc(100% - 100px)', overflowY: 'auto' }}>
                    <h2 className='mb-4'>View QR Codes</h2>

                    <div className='d-flex justify-content-end align-items-center mb-4'>
                        <div className='me-2'>
                            <button
                                className='btn btn-primary rounded-pill shadow-lg px-4'
                                onClick={downloadAllQRCodes}
                                disabled={loading || filteredQRCodes.length === 0}
                            >
                                {loading ? 'Loading...' : 'Download All'}
                            </button>
                        </div>
                        <DatePicker
                            selected={filterDate}
                            onChange={handleFilterByDate}
                            className="form-control w-100 shadow-sm border border-primary"
                            placeholderText="12, October, 2024"
                            dateFormat="dd, MMMM, yyyy"
                            popperClassName="react-datepicker-custom"
                        />
                    </div>

                    <table className='table table-hover shadow-sm rounded'>
                        <thead className='thead-light'>
                            <tr>
                                <th className='text-center'>UUID</th>
                                <th className='text-center'>QR Code</th>
                                <th className='text-center'>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredQRCodes.length > 0 ? (
                                filteredQRCodes.map((qrCode) => (
                                    <tr key={qrCode.uuid} className='text-center'>
                                        <td className='align-middle'>{qrCode.uniqueIdentifier}</td>
                                        <td className='align-middle'>
                                            <img
                                                src={`${BASE_URL}${qrCode.imagePath}`}
                                                alt="QR Code"
                                                width="100"
                                                className='img-thumbnail shadow-sm'
                                            />
                                        </td>
                                        <td className='align-middle'>
                                            <button
                                                className='btn btn-outline-success rounded-pill shadow-sm'
                                                onClick={() => downloadQRCode(qrCode.imagePath, qrCode.uniqueIdentifier)}
                                            >
                                                Download
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className='text-center'>
                                        {loading ? 'Loading QR codes...' : 'No QR codes found'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <div className="d-flex justify-content-between mt-3">
                        <button
                            className="rounded-5 btn btn-outline-primary"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 0 || loading}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage + 1} of {totalPages}</span>
                        <button
                            className="rounded-5 btn btn-outline-primary"
                            onClick={handleNextPage}
                            disabled={currentPage + 1 === totalPages || loading}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewQRCodes;
