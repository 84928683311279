import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Splash from '../pages/Splash';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import ForgetPassword from '../pages/ForgetPassword';
import DeceasedProfile from '../pages/DeceasedProfile';
import QRRedirectScreen from '../pages/QRRedirectScreen';
import MemorialProfiles from '../pages/MemorialProfiles';
import NotificationsScreen from '../pages/NotificationsScreen';
import RequestScreen from '../pages/RequestsScreen';
import DeclinedRequest from '../pages/DeclinedRequest';
import EditDeceasedProfile from '../pages/EditDeceasedProfile';
import EditOwnerProfile from '../pages/EditOwnerProfile';
import OwnerProfileScreen from '../pages/OwnerProfileScreen';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import AdminLogin from '../pages/admin/AdminLogin';
import Dashboard from '../pages/admin/Dashboard';
import DeceasedProfileScreen from '../pages/DeceasedProfileScreen';
import CreateQRCodes from '../pages/admin/CreateQRCodes';
import ViewQRCodes from '../pages/admin/ViewQRCodes';
import Users from '../pages/admin/Users';
import QRMemorialProfiles from '../pages/admin/QRMemorialProfiles';
import AdminProfile from '../pages/admin/AdminProfile';
import AdminPublicRoute from './AdminPublicRoute';
import AdminPrivateRoute from './AdminPrivateRoute';

const AdminRedirect = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        navigate('/admin/dashboard');
    }, [navigate]);

    return null; 
};

function Navigator() {
    
    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<Splash />} />
                <Route path="/d-profile/:id" element={<DeceasedProfileScreen />} />
                <Route path="/login" element={<PublicRoute element={Login} />} />
                <Route path="/admin/login" element={<AdminPublicRoute element={AdminLogin} />} />
                <Route path="/admin/dashboard" element={<AdminPrivateRoute element={Dashboard} />} />
                <Route path="/admin/create-qr" element={<AdminPrivateRoute element={CreateQRCodes} />} />
                <Route path="/admin/view-qr" element={<AdminPrivateRoute element={ViewQRCodes} />} />
                <Route path="/admin/users" element={<AdminPrivateRoute element={Users} />} />
                <Route path="/admin/memorial-profiles" element={<AdminPrivateRoute element={QRMemorialProfiles} />} />
                <Route path="/admin/profile" element={<AdminPrivateRoute element={AdminProfile} />} />
                <Route path="/admin" element={<AdminRedirect />} /> {/* Redirect /admin to /admin/dashboard */}
                <Route path="/signup" element={<PublicRoute element={Signup} />} />
                <Route path="/forget-password" element={<PublicRoute element={ForgetPassword} />} />
                <Route path="/deceased-profile" element={<PrivateRoute element={DeceasedProfile} />} />
                <Route path="/redirect/:uuid" element={<QRRedirectScreen />} />
                <Route path="/memorial-profiles" element={<PrivateRoute element={MemorialProfiles} />} />
                <Route path="/notifications" element={<PrivateRoute element={NotificationsScreen} />} />
                <Route path="/requests" element={<PrivateRoute element={RequestScreen} />} />
                <Route path="/declined-request" element={<PrivateRoute element={DeclinedRequest} />} />
                <Route path="/edit-deceased-profile/:id" element={<PrivateRoute element={EditDeceasedProfile} />} />
                <Route path="/edit-profile/:id" element={<PrivateRoute element={EditOwnerProfile} />} />
                <Route path="/profile" element={<PrivateRoute element={OwnerProfileScreen} />} />
            </Routes>
        </Router>
    );
}

export default Navigator;
