import React from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import AdminServices from '../../services/AdminServices';
import { ToastContainer, toast } from 'react-toastify';


const Sidebar = () => {
    const location = useLocation();

    const currentPath = location.pathname;

    const isActive = (path) => currentPath === path ? 'active' : '';

    const handleLogout = async () => {
        AdminServices.logoutAdmin();
        toast.success("Logged out successfully.");
        window.location.reload()
    };

    return (
        <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
            <ToastContainer toastClassName='toaster' />
            <CDBSidebar textColor="#333" backgroundColor="#f0f0f0">
                <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
                    <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={logo} alt="" style={{ width: '30px' }} />
                        <h6 className="ms-2 mb-0">Heaven's Code</h6>
                    </div>
                </CDBSidebarHeader>

                <CDBSidebarContent className="sidebar-content">
                    <CDBSidebarMenu>
                        <NavLink to="/admin/dashboard">
                            <CDBSidebarMenuItem icon="columns" className={`text-dark ${isActive('/admin/dashboard')}`}>
                                Dashboard
                            </CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/create-qr">
                            <CDBSidebarMenuItem icon="plus-circle" className={`text-dark ${isActive('/admin/create-qr')}`}>
                                Create QR Codes
                            </CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/view-qr">
                            <CDBSidebarMenuItem icon="table" className={`text-dark ${isActive('/admin/view-qr')}`}>
                                View QR Codes
                            </CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/users">
                            <CDBSidebarMenuItem icon="user" className={`text-dark ${isActive('/admin/users')}`}>
                                Users
                            </CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/memorial-profiles">
                            <CDBSidebarMenuItem icon="heart" className={`text-dark ${isActive('/admin/memorial-profiles')}`}>
                                Memorial Profiles
                            </CDBSidebarMenuItem>
                        </NavLink>
                    </CDBSidebarMenu>
                </CDBSidebarContent>

                <CDBSidebarFooter>
                    <div>
                        <CDBSidebarContent className="sidebar-content">
                            <CDBSidebarMenu>
                                <NavLink to="#" onClick={handleLogout}>
                                    <CDBSidebarMenuItem icon="sign-out-alt" className={`text-dark ${isActive('/logout')}`}>
                                        Logout
                                    </CDBSidebarMenuItem>
                                </NavLink>
                            </CDBSidebarMenu>
                        </CDBSidebarContent>
                    </div>
                </CDBSidebarFooter>
            </CDBSidebar>
        </div>
    );
};

export default Sidebar;
