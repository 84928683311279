import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import RequestNotification from '../components/RequestNotification';
import { formatDateTime } from '../functions/CommonFunctions';
import { toast, ToastContainer } from 'react-toastify';
import useRequestServices from '../services/RequestServices';

function RequestScreen() {
    const location = useLocation();
    const userId = location.state?.user?.id;

    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    const { getRequests, ApproveRequest, DeclineRequest } = useRequestServices();

    const getViewRequests = async () => {
        try {
            if (userId) {
                const response = await getRequests(userId);
                // console.log("Get Requests: ", response);
                setRequests(response);
            } else {
                toast.error("User ID is not available.");
            }
        } catch (error) {
            console.error("Error fetching requests:", error);
            toast.error("Failed to fetch requests.");
        } finally {
            setLoading(false);
        }
    };

    const handleAllow = async (requestId) => {
        setLoading(true);
        try {
            await ApproveRequest(requestId);
            setRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
            toast.success("Request Accepted Successfully");
        } catch (error) {
            console.error("Error allowing request:", error);
            toast.error("Failed to accept request.");
        } finally {
            setLoading(false);
        }
    };

    const handleDecline = async (requestId) => {
        setLoading(true);
        try {
            await DeclineRequest(requestId);
            setRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
            toast.success("Request Declined Successfully");
        } catch (error) {
            console.error("Error declining request:", error);
            toast.error("Failed to decline request.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getViewRequests();
    }, [userId]);

    return (
        <div>

            <div className='main_container h-auto' style={{ minHeight: "80vh" }}>
                <ToastContainer toastClassName='toaster' />
                <div className={`screen_container d-flex align-items-center ${loading && "justify-content-center"}`} style={{ height: loading ? "80vh" : "auto", minHeight: "80vh" }}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className='w-100'>
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                <div className='w-50'>
                                    <Header />
                                </div>
                            </div>
                            {requests.length > 0 ? (
                                requests.map((request) => (
                                    <RequestNotification
                                        key={request.id}
                                        message={`${request.requestingUser.displayName} wants to view ${request.medallion.displayName}'s profile.`}
                                        requester={request.requestingUser}
                                        requestTime={formatDateTime(request.requestDate)}
                                        onAllow={() => handleAllow(request.id)}
                                        onDecline={() => handleDecline(request.id)}
                                    />
                                ))
                            ) : (
                                <p className='text-black text-center'>No requests available.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
}

export default RequestScreen;
