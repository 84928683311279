import React, { useState, useEffect, useRef, useCallback } from 'react';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Button, Modal } from 'react-bootstrap';
import Loader from '../components/Loader';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/CropImage';
import profile from '../assets/images/person.jpeg';
import banner from '../assets/images/banner.jpg';
import edit from '../assets/icons/pen-24.png';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthServices from '../services/AuthServices';
import useMedallionServices from '../services/MedallionServices';
import useUploadFileServices from '../services/UploadFileServices';

function DeceasedProfile() {
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [relation, setRelation] = useState('');
  const [headline, setHeadline] = useState('');
  const [showProfileCropper, setShowProfileCropper] = useState(false);
  const [showBannerCropper, setShowBannerCropper] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [cropProfile, setProfileCrop] = useState({ x: 0, y: 0 });
  const [zoomProfile, setProfileZoom] = useState(1);
  const [croppedProfileAreaPixels, setCroppedProfileAreaPixels] = useState(null);
  const [croppedProfileImageFile, setCroppedProfileImageFile] = useState(null);
  const [cropBanner, setBannerCrop] = useState({ x: 0, y: 0 });
  const [zoomBanner, setBannerZoom] = useState(1);
  const [croppedBannerAreaPixels, setCroppedBannerAreaPixels] = useState(null);
  const [croppedBannerImageFile, setCroppedBannerImageFile] = useState(null);
  const [dob, setDob] = useState('');
  const [dod, setDod] = useState('');
  const [qrCodeId, setQrCodeId] = useState('');
  const [about, setAbout] = useState('');
  const [profileStatus, setProfileStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { getUserDetails } = useAuthServices();

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const { data } = await getUserDetails(navigate);
        const params = new URLSearchParams(location.search);
        // console.log(params);
        const idParam = params.get('uuid');
        if (idParam) {
          // console.log(idParam);
          setQrCodeId(idParam);
        } else {
          navigate('/profile')
        }
        setUser(data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [navigate]);

  const validateFields = () => {
    if (!name) {
      toast.error('Name is required.');
      return false;
    }
    if (!headline) {
      toast.error('Headline is required.');
      return false;
    }
    if (!dob) {
      toast.error('Date of birth is required.');
      return false;
    }
    if (!dod) {
      toast.error('Date of death is required.');
      return false;
    }
    if (!about) {
      toast.error('About section is required.');
      return false;
    }
    if (!profileStatus) {
      toast.error('Profile status is required.');
      return false;
    }
    return true;
  };

  const { createMedallion } = useMedallionServices();
  const { uploadFile } = useUploadFileServices();

  const createDeceased = async () => {
    if (!validateFields()) return;

    setLoading(true);
    try {
      const profileFile = fileInputRef.current?.files[0];
      const bannerFile = bannerInputRef.current?.files[0];

      const profilepic = profileFile || croppedProfileImageFile ? await uploadFile(croppedProfileImageFile || profileFile) : null;
      const bannerpic = bannerFile || croppedBannerImageFile ? await uploadFile(croppedBannerImageFile || bannerFile) : null;

      const medallionData = {
        displayName: name,
        relationship: relation,
        imagePath: profilepic?.data?.url || profileImage || profile,
        thumbNailPath: bannerpic?.data?.url || bannerImage || banner,
        bioInformation: headline,
        about: about,
        isPrivate: profileStatus === 'private',
        dateOfBirth: dob,
        dateOfDeath: dod,
        qrCodeId: qrCodeId
      };

      const res = await createMedallion(medallionData);
      if (res) {
        toast.success('Profile created successfully!');
        setName('');
        setAbout('');
        setRelation('');
        setDob('');
        setDod('');
        setHeadline('');
        setProfileStatus('');
        setProfileImage(null);
        setBannerImage(null);
        navigate('/memorial-profiles')
      }
    } catch (error) {
      toast.error('Failed to create profile.');
      console.error('Error creating profile:', error);
    } finally {
      setLoading(false);
    }
  };

  const cancelProfileImage = () => {
    setShowProfileCropper(false);
    setProfileImage(user?.imagePath || profile);
  };

  const cancelBannerImage = () => {
    setShowBannerCropper(false);
    setBannerImage(user?.bannerPath || banner);
  };

  const handleProfileFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      setShowProfileCropper(true);
    }
  };

  const handleBannerFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setBannerImage(imageUrl);
      setShowBannerCropper(true);
    }
  };

  const onCropProfileComplete = useCallback(async () => {
    try {
      const { file, url } = await getCroppedImg(profileImage, croppedProfileAreaPixels);
      const uniqueId = Date.now();
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${name}-${uniqueId}.${fileExtension}`;
      const renamedFile = new File([file], uniqueFileName, { type: file.type });
      setProfileImage(url);
      setCroppedProfileImageFile(renamedFile);
      setShowProfileCropper(false);
    } catch (error) {
      console.error('Error cropping profile image:', error);
    }
  }, [croppedProfileAreaPixels, profileImage, name]);

  const onCropBannerComplete = useCallback(async () => {
    try {
      const { file, url } = await getCroppedImg(bannerImage, croppedBannerAreaPixels);
      const uniqueId = Date.now();
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${name}-${uniqueId}.${fileExtension}`;
      const renamedFile = new File([file], uniqueFileName, { type: file.type });
      setBannerImage(url);
      setCroppedBannerImageFile(renamedFile);
      setShowBannerCropper(false);
    } catch (error) {
      console.error('Error cropping banner image:', error);
    }
  }, [croppedBannerAreaPixels, bannerImage, name]);

  return (
    <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
      <div className={`screen_container d-flex justify-content-center align-items-center`} style={{ height: loading ? '84.2vh' : 'auto' }}>
        <ToastContainer toastClassName='toaster' />
        {loading ? <Loader /> : (
          <div className='w-100'>
            <Header />
            <div className='d-flex flex-column align-items-center mt-3'>
              <p className='text-black text-center fs-3 w-50 mt-3 mb-5'>
                Store the Memory of Your loved ones
              </p>
              <Form className='single-form d-flex flex-column justify-content-center align-items-center w-100'>
                <div className='position-relative mb-5 d-flex flex-column align-items-center'>
                  <div className='banner-container position-relative mb-3'>
                    <img
                      src={bannerImage || banner}
                      alt='Banner'
                      className='rounded-3 banner'
                    />
                    <img
                      src={edit}
                      alt='Edit Banner'
                      onClick={() => bannerInputRef.current.click()}
                      className='edit-icon-banner position-absolute'
                    />
                  </div>
                  <div className='profile-pic-container position-absolute mb-3' style={{ top: 100 }}>
                    <img
                      src={profileImage || profile}
                      alt='Profile'
                      className='rounded-circle profile-pic'
                    />
                    <img
                      src={edit}
                      alt='Edit Profile'
                      onClick={() => fileInputRef.current.click()}
                      className='edit-icon position-absolute'
                    />
                  </div>
                </div>
                <Form.Group className="mb-3 w-100" controlId="name">
                  <Form.Control type="text" placeholder="Name" className='shopnow-input' value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 w-100" controlId="headline">
                  <Form.Control as="textarea" rows={3} placeholder="Bio Information" className='shopnow-input' value={headline} onChange={(e) => setHeadline(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 w-100" controlId="relationship">
                  <Form.Control type="text" placeholder="Relationship" className='shopnow-input' value={relation} onChange={(e) => setRelation(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 w-100" controlId="imagePath">
                  <Form.Control type="file" placeholder="Image Path" className='file-input' ref={fileInputRef} onChange={handleProfileFileChange} style={{ display: 'none' }} />
                </Form.Group>
                <Form.Group className="mb-3 w-100" controlId="bannerPath">
                  <Form.Control type="file" placeholder="Banner Path" className='file-input' ref={bannerInputRef} onChange={handleBannerFileChange} style={{ display: 'none' }} />
                </Form.Group>
                <Form.Group className="mb-3 w-100" controlId="dob">
                  <Form.Label className='text-black pb-2'>Date of Birth</Form.Label>
                  <Form.Control type="date" placeholder="Date of Birth" className='shopnow-input' value={dob} onChange={(e) => setDob(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 w-100" controlId="dod">
                  <Form.Label className='text-black pb-2'>Date of Death</Form.Label>
                  <Form.Control type="date" placeholder="Date of Death" className='shopnow-input' value={dod} onChange={(e) => setDod(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 w-100" controlId="about">
                  <Form.Control as="textarea" rows={3} placeholder="About" className='shopnow-input' value={about} onChange={(e) => setAbout(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 w-100" controlId="profileStatus">
                  <Form.Select className='shopnow-input' value={profileStatus} onChange={(e) => setProfileStatus(e.target.value)}>
                    <option value="">Choose Profile Status</option>
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </Form.Select>
                </Form.Group>
                <Button className='paybtn my-2 w-50 w-md-25 w-lg-25 w-sm-25 w-xl-25' id="paybtn" type="button" onClick={createDeceased}>
                  Create Profile
                </Button>
              </Form>
            </div>
          </div>
        )}
      </div>

      <Modal show={showProfileCropper} onHide={() => setShowProfileCropper(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop Your Profile Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='crop-container'>
            <Cropper
              image={profileImage}
              crop={cropProfile}
              zoom={zoomProfile}
              aspect={1}
              onCropChange={setProfileCrop}
              onZoomChange={setProfileZoom}
              onCropComplete={(croppedArea, croppedAreaPixels) => setCroppedProfileAreaPixels(croppedAreaPixels)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='rounded-5' onClick={cancelProfileImage}>
            Cancel
          </Button>
          <Button variant='primary' className='rounded-5 px-3' onClick={onCropProfileComplete}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBannerCropper} onHide={() => setShowBannerCropper(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop Your Banner Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='crop-container'>
            <Cropper
              image={bannerImage}
              crop={cropBanner}
              zoom={zoomBanner}
              aspect={2.5}
              onCropChange={setBannerCrop}
              onZoomChange={setBannerZoom}
              onCropComplete={(croppedArea, croppedAreaPixels) => setCroppedBannerAreaPixels(croppedAreaPixels)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='rounded-5' onClick={cancelBannerImage}>
            Cancel
          </Button>
          <Button variant='primary' className='rounded-5 px-3' onClick={onCropBannerComplete}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeceasedProfile;
