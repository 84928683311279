import React from 'react'
import logo from '../assets/images/logo.png'

function Header() {
  return (
    <div>
      <div className='d-flex align-items-center'>
        <img src={logo} alt="Heaven's Code" className='logo' />
        <p className='text-black ms-2 mb-0 title'>Heaven's Code</p>
      </div>
    </div>
  )
}

export default Header