import React, { useState, useEffect, useRef, useCallback } from 'react';
import Sidebar from '../../components/admin/Sidebar';
import NavBar from '../../components/admin/NavBar';
import Cropper from 'react-easy-crop';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { getCroppedImg } from '../../utils/CropImage';
import AdminServices from '../../services/AdminServices';
import edit from '../../assets/icons/pen-24.png';
import profile from '../../assets/images/person.jpeg';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../services/BASE_URL';
import LoadingIndicator from '../../components/LoadingIndicator';

function AdminProfile() {
    const [loading, setLoading] = useState(false);
    const [admin, setAdmin] = useState({
        displayName: '',
        email: '',
        imagePath: profile
    });
    const [id, setId] = useState(1);
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: ''
    });

    const [profileImage, setProfileImage] = useState(profile);
    const [cropper, setCropper] = useState({
        showCropper: false,
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedAreaPixels: null,
        croppedImageFile: null
    });

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [passwords, setPasswords] = useState({
        previousPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const response = await AdminServices.getAdminDetails(navigate);
                if (response) {
                    const { displayName, email, imagePath } = response.data;
                    setAdmin({ displayName, email, imagePath });
                    setId(response.data.id);
                    setFormData({ name: displayName, email, password: '' });
                    setProfileImage(imagePath || profile);
                }
            } catch (error) {
                console.error('Error fetching admin profile:', error);
            }
        };
        fetchAdminData();
    }, []);

    const validateFields = () => {
        if (!formData.name) {
            toast.error('Name is required.');
            return false;
        }
        if (formData.password && formData.password.length < 6) {
            toast.error('Password should be at least 6 characters long.');
            return false;
        }
        return true;
    };

    const updateProfile = async () => {
        setLoading(true);
        if (!validateFields()) {
            setLoading(false);
            return;
        }
        try {
            let profilepic;
            let path = admin.imagePath;

            if (cropper.croppedImageFile) {
                profilepic = await AdminServices.uploadFile(cropper.croppedImageFile);
                path = `${BASE_URL}${profilepic?.url || path}`;
            }

            const updatedData = {
                displayName: formData.name,
                email: formData.email,
                imagePath: `${path}`,
                ...(formData.password && { password: formData.password }),
            };

            const response = await AdminServices.updateProfile(id, updatedData);

            if (response.statusCode === 200) {
                toast.success('Profile updated successfully.');
            } else {
                toast.error('Failed to update profile.');
            }
        } catch (error) {
            toast.error('Failed to update profile.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const cancelImage = () => {
        setCropper(prev => ({
            ...prev,
            showCropper: false,
            croppedImageFile: null
        }));
        setProfileImage(admin.imagePath);
    };

    const onCropComplete = useCallback(async () => {
        try {
            const { file, url } = await getCroppedImg(profileImage, cropper.croppedAreaPixels);
            const uniqueId = Date.now();
            const fileExtension = file.name.split('.').pop();
            const uniqueFileName = `${formData.name}-${uniqueId}.${fileExtension}`;
            const renamedFile = new File([file], uniqueFileName, { type: file.type });

            setProfileImage(url);
            setCropper(prev => ({
                ...prev,
                croppedImageFile: renamedFile,
                showCropper: false,
                croppedAreaPixels: null 
            }));
        } catch (error) {
            console.error(error);
        }
    }, [cropper.croppedAreaPixels, profileImage, formData.name]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setProfileImage(imageUrl);
            setCropper(prev => ({
                ...prev,
                showCropper: true
            }));
        }
    };

    const handlePasswordChange = async () => {
        if (passwords.newPassword !== passwords.confirmPassword) {
            toast.error('New password and confirm password do not match.');
            return;
        }
        if (passwords.previousPassword === '' || passwords.newPassword === '') {
            toast.error('Please fill in all the fields.');
            return;
        }
        setLoading(true);
        try {
            const response = await AdminServices.updatePassword(id, passwords.previousPassword, passwords.newPassword, passwords.confirmPassword);
            if (response.statusCode === 200) {
                toast.success('Password changed successfully.');
            } else {
                toast.error('Failed to change password.');
            }
        } catch (error) {
            toast.error('Error changing password.');
        } finally {
            setLoading(false);
            setShowPasswordModal(false);
        }
    };

    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                <div className='container mt-4'>
                    <div className='d-flex align-items-center mb-5 justify-content-between mx-3'>
                        <h2 className='mb-0'>Edit Admin Profile</h2>
                        <Button variant="outline-danger rounded-5 d-block" onClick={() => setShowPasswordModal(true)}>
                            Change Password
                        </Button>
                    </div>
                    {/* <ToastContainer toastClassName='toaster' /> */}
                    <div className='d-flex flex-column align-items-center'>
                        <div className='profile-pic-container position-relative'>
                            <img
                                src={profileImage || profile}
                                alt='Profile'
                                className='rounded-circle profile-pic'
                            />
                            <img
                                src={edit}
                                alt='edit'
                                onClick={() => fileInputRef.current.click()}
                                className='edit-icon position-absolute'
                            />
                        </div>
                        <Form className='w-50 mt-3'>
                            <Form.Group className='mb-3'>
                                <Form.Control
                                    type='text'
                                    placeholder='Name'
                                    value={formData.name}
                                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value })) }
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Control
                                    type='email'
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value })) }
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Control
                                    type='file'
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                            </Form.Group>
                            {
                                loading ?
                                    <LoadingIndicator />
                                    : <Button onClick={updateProfile} className='btn-primary rounded-5 d-block mx-auto'>
                                        Update Profile
                                    </Button>
                            }
                        </Form>
                    </div>
                </div>
            </div>

            <Modal show={cropper.showCropper} onHide={cancelImage} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Crop Your Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='crop-container'>
                        <Cropper
                            image={profileImage}
                            crop={cropper.crop}
                            zoom={cropper.zoom}
                            aspect={1}
                            onCropChange={(crop) => setCropper(prev => ({ ...prev, crop }))}
                            onCropComplete={(croppedArea, croppedAreaPixels) => setCropper(prev => ({
                                ...prev,
                                croppedAreaPixels
                            }))}
                            onZoomChange={(zoom) => setCropper(prev => ({ ...prev, zoom }))}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={cancelImage} className='rounded-5'>Cancel</Button>
                    <Button variant='primary' onClick={onCropComplete}className='rounded-5'>Crop</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className='mb-3'>
                            <Form.Control
                                type='password'
                                placeholder='Current Password'
                                value={passwords.previousPassword}
                                onChange={(e) => setPasswords(prev => ({ ...prev, previousPassword: e.target.value }))}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Control
                                type='password'
                                placeholder='New Password'
                                value={passwords.newPassword}
                                onChange={(e) => setPasswords(prev => ({ ...prev, newPassword: e.target.value }))}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Control
                                type='password'
                                placeholder='Confirm New Password'
                                value={passwords.confirmPassword}
                                onChange={(e) => setPasswords(prev => ({ ...prev, confirmPassword: e.target.value }))}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' className='rounded-5' onClick={() => setShowPasswordModal(false)}>Cancel</Button>
                    <Button variant='primary' className='rounded-5' onClick={handlePasswordChange}>Change Password</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminProfile;
