import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/admin/Sidebar'
import NavBar from '../../components/admin/NavBar'
import WelcomeAlert from '../../components/admin/WelcomeAlert'
import AdminServices from '../../services/AdminServices';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';

function Dashboard() {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const admin = await AdminServices.getAdminDetails(navigate);
                // console.log('Admin Details:', admin);
                setUser(admin?.data);
            } catch (error) {
                console.error("Error fetching user details:", error);
                toast.error("Unexpected Error Occurred.");
            } finally {
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, [navigate]);

    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                {
                    loading ? <div className='d-flex align-items-center justify-content-center' style={{minHeight: "80vh"}}>
                        <Loader />
                    </div> : <WelcomeAlert name={user?.displayName} />
                }
            </div>
        </div>
    )
}

export default Dashboard