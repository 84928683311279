import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import { toast } from 'react-toastify';
import useAuthServices from './AuthServices';

const API_URL = `${BASE_URL}/api/Medallion`;

const useMedallionServices = () => {
    const { getToken } = useAuthServices();
    const token = getToken();

    const getNumberofMedallions = async (userid) => {
        // console.log("token", token);
        try {
            const response = await axios.get(`${API_URL}/GetNumberOfMedallionByUserId?UserId=${userid}`, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                },
            });
            return response;
        } catch (error) {
            console.error("Error fetching number of medallions:", error);
            // toast.error("Failed to fetch number of medallions.");
        }
    };
    
    const createMedallion = async (medallionData) => {
        try {
            const response = await axios.post(`${API_URL}/CreateMedallion`, medallionData, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json-patch+json',
                },
            });
            return response;
        } catch (error) {
            console.error("Error creating medallion:", error);
            toast.error("Failed to create medallion.");
        }
    };
    
    const getMedallionByID = async (id) => {
        try {
            const response = await axios.get(`${API_URL}/GetMedallionById?Id=${id}`, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json-patch+json',
                },
            });
            return response;
        } catch (error) {
            if (error.response && error.response.status === 403) {
                return { statusCode: 403, message: "Forbidden: You do not have permission to access this resource." };
            } else {
                console.error("Error fetching medallion by ID:", error);
                throw new Error(error.response ? error.response.data.message : "An error occurred while fetching the medallion.");
            }
        }
    };
    const getByID = async (id) => {
        try {
            const response = await axios.get(`${API_URL}/GetById?Id=${id}`, {
                headers: {
                    'Content-Type': 'application/json-patch+json',
                },
            });
            return response;
        } catch (error) {
            if (error.response && error.response.status === 403) {
                return { statusCode: 403, message: "Forbidden: You do not have permission to access this resource." };
            } else {
                console.error("Error fetching medallion by ID:", error);
                throw new Error(error.response ? error.response.data.message : "An error occurred while fetching the medallion.");
            }
        }
    };
    
    const updateMedallion = async (id, medallionData) => {
        try {
            const response = await axios.put(`${API_URL}/Put`, { ...medallionData, id }, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json-patch+json',
                },
            });
            // console.log(response);
            return response;
        } catch (error) {
            console.error("Error updating medallion:", error);
            toast.error("Failed to update medallion.");
        }
    };

    return {
        getNumberofMedallions,
        createMedallion,
        getMedallionByID,
        updateMedallion,
        getByID
    };
};

export default useMedallionServices;


